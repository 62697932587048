var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: 'products',
                params: { setting: _vm.productsSettings },
            }}},[_vm._v(" Products ")]),_c('v-tab',{attrs:{"to":{
                name: 'processes',
                params: {
                    setting: _vm.processesSettings,
                },
            }}},[_vm._v(" Processes ")])],1),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-card-title',{ref:"title"},[_c('span',[_vm._v("Work Orders")])]),_c('v-card-subtitle',{ref:"subtitle",staticClass:"my-0"},[_c('v-divider')],1),_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }