<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'products',
                    params: { setting: productsSettings },
                }"
            >
                Products
            </v-tab>
            <v-tab
                :to="{
                    name: 'processes',
                    params: {
                        setting: processesSettings,
                    },
                }"
            >
                Processes
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title ref="title">
                <span>Work Orders</span>
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="my-0">
                <v-divider />
            </v-card-subtitle>

            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'WorkOrderSettings',
    components: {},
    props: {
        setting: Object,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            errorMsg: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            productsSettings: {},
            operatorsSettings: {},
            processesSettings: {},
        }
    },
    updated() {
        this.onResize()
    },
    created() {
        this.productsSettings = this.setting.products
        this.operatorsSettings = this.setting.operators
        this.processesSettings = this.setting.processes
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
    },
}
</script>
